import { createRouter, createWebHistory } from 'vue-router'
import ReportsDashboard from '../views/Reports/ReportsDashboard.vue'
import AuthLogin from '../views/Authenticaction/SignIn/LoginPage.vue'
import ReportsManager from '../views/Reports/ReportsManager.vue'
import UnauthorizedPage from '../views/Authenticaction/UnauthorizedPage.vue'
import { auth } from '@/config/firebase'

const routes = [
  {
    path: '/auth/login',
    name: 'login',
    component: AuthLogin,
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: UnauthorizedPage,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/',
    name: 'home',
    component: ReportsDashboard,
  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportsManager,
    meta: {
      requireAuth: true,
      role: ['admin'],
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const waitForUserData = async authStore => {
  return new Promise(resolve => {
    const checkUserData = setInterval(() => {
      if (authStore.userProfile && authStore.userProfile.role) {
        clearInterval(checkUserData)
        resolve(true)
      }
    }, 100)
  })
}

router.beforeEach(async (to, from, next) => {
  const { useAuthStore } = await import('@/views/Authenticaction/store')
  const authStore = useAuthStore()

  const user = auth.currentUser

  if (to.matched.some(record => record.meta.requireAuth)) {
    if (!user) {
      next({ path: '/auth/login' })
    } else {
      await waitForUserData(authStore)
      const requiredRole = to.meta.role
      if (!requiredRole || requiredRole.includes(authStore.userProfile.role)) {
        next()
      } else {
        next({ path: '/unauthorized' })
      }
    }
  } else {
    next()
  }
})

export default router
